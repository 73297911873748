module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"writeops","accessToken":"MC5YNi14b1JNQUFDUUFGanpk.Rm19Ej5R77-9bu-_ve-_vVTvv71977-9WxNu77-9SBoSE--_vWBm77-9E--_ve-_ve-_ve-_vQ8","fetchLinks":[],"schemas":{"services":{"Main":{"title":{"type":"StructuredText","config":{"single":"heading3","label":"Title"}},"description":{"type":"StructuredText","config":{"single":"paragraph","label":"Description"}},"meta_details":{"type":"StructuredText","config":{"multi":"list-item","label":"Meta details"}},"category":{"type":"Select","config":{"options":["content","marketing"],"label":"Category"}},"price":{"type":"Text","config":{"label":"Price"}},"price_unit":{"type":"Text","config":{"label":"Price unit","placeholder":"e.g. for £1 per word enter 'word'"}}}},"testimonials":{"Main":{"company_name":{"type":"Text","config":{"label":"Company Name"}},"quote":{"type":"StructuredText","config":{"single":"paragraph","label":"Quote"}},"logo":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Logo"}},"comany_website":{"type":"Link","config":{"allowTargetBlank":true,"label":"Comany Website"}}}}},"lang":"*","prismicToolbar":true,"imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15,"q":50},"typePathsFilenamePrefix":"prismic-typepaths---writeops"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"WriteOps","short_name":"WriteOps","start_url":"/","background_color":"#fff","theme_color":"#212121","display":"standalone","icon":"static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"555e75eb568d8967feb2496a39f87645"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-180762660-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
